/* eslint-disable @typescript-eslint/no-explicit-any */
import { createStore, SetState } from '@oneappexchange/lwc-wired';
import { InMemoryStorageProvider, IStorageProvider, IToggle, UnleashClient } from 'unleash-proxy-client';
import { GetState, State } from 'zustand';
import {
    FEATURE_FLAG_APP_NAME,
    FEATURE_FLAG_CLIENT_KEY,
    FEATURE_FLAG_PROXY_URL,
    IS_DEVELOPMENT,
} from '~/modules/config';
import { handleNetworkWarning } from '~/modules/utilities/axios_utilities';
import { isClient, logger } from '~/modules/utilities/cross_env_utils';
import { deepEquals } from '~/modules/utilities/object_utils';

export type FeatureFlag = keyof schema.current.FeatureFlags;
export type FeatureFlagsRecord = Record<FeatureFlag, boolean>;

export const enum FeatureFlagStoreStatus {
    UNINITIALIZED = 'UNINITIALIZED',
    LOADING = 'LOADING',
    LOADED = 'LOADED',
    ERROR = 'ERROR',
}

export interface FeatureFlagsState extends State {
    status: FeatureFlagStoreStatus;
    featureFlags: FeatureFlagsRecord;
    unleash?: UnleashClient;
    context?: any;
}

const initialState: FeatureFlagsState = {
    status: FeatureFlagStoreStatus.UNINITIALIZED,
    featureFlags: Object.create(null),
};

export const featureFlagsStore = createStore(
    initialState,
    (set: SetState<FeatureFlagsState>, get: GetState<FeatureFlagsState>) => ({
        async getFeatureFlags({
            context,
            defaultValues,
            storageProvider,
        }: {
            context?: any;
            defaultValues?: FeatureFlagsRecord;
            storageProvider?: IStorageProvider;
        } = {}): Promise<void> {
            if (!FEATURE_FLAG_PROXY_URL) {
                throw new Error('Cannot fetch feature flags without "FEATURE_FLAG_PROXY_URL" env var');
            }
            if (!FEATURE_FLAG_CLIENT_KEY) {
                throw new Error('Cannot fetch feature flags without "FEATURE_FLAG_CLIENT_KEY" env var');
            }
            if (!FEATURE_FLAG_APP_NAME) {
                throw new Error('Cannot fetch feature flags without "FEATURE_FLAG_APP_NAME" env var');
            }

            let unleash = get().unleash;

            // If we already created unleash client, and the context did not change, skip re-fetching FFs.
            if (unleash && deepEquals(get().context, context)) {
                // If current featureFlags differ from defaultValues, update them to the new defaultValues.
                if (defaultValues && !deepEquals(get().featureFlags, defaultValues)) {
                    set(function setFeatureFlags(draft) {
                        draft.featureFlags = defaultValues;
                        logger.debugIfDev((_) =>
                            _(
                                `Feature Flags Set${context ? ` (${JSON.stringify(context)}}` : ''}:\n${JSON.stringify(
                                    defaultValues,
                                    null,
                                    2,
                                )}`,
                                'feature_flags_store',
                            ),
                        );
                    });
                }
                // Skip re-fetching FFs.
                return Promise.resolve();
            }

            // Create a promise that will resolve when featureFlagsStore's status field is LOADED or ERROR.
            // This promise will provide consumers an easy way to wait for FFs to be loaded.
            let resolvePromise: undefined | (() => void);
            let rejectPromise: undefined | (() => void);
            const statusPromise = new Promise<void>((resolve, reject) => {
                resolvePromise = resolve;
                rejectPromise = reject;
            });
            let unsubscribe: undefined | (() => void) = featureFlagsStore.subscribe((status) => {
                switch (status) {
                    case FeatureFlagStoreStatus.LOADED: {
                        unsubscribe?.();
                        unsubscribe = undefined;
                        resolvePromise?.();
                        resolvePromise = undefined;
                        break;
                    }
                    case FeatureFlagStoreStatus.ERROR: {
                        unsubscribe?.();
                        unsubscribe = undefined;
                        rejectPromise?.();
                        rejectPromise = undefined;
                        break;
                    }
                    default: {
                        /* noop */
                    }
                }
            }, selectStatus);

            try {
                if (unleash) {
                    unleash.stop();
                }

                unleash = new UnleashClient({
                    url: `${FEATURE_FLAG_PROXY_URL}/proxy`,
                    clientKey: FEATURE_FLAG_CLIENT_KEY,
                    appName: FEATURE_FLAG_APP_NAME,
                    disableRefresh: isClient,
                    fetch: isClient ? undefined : (globalThis as AnyObject).fetch,
                    storageProvider: isClient ? undefined : storageProvider || new InMemoryStorageProvider(),
                });

                // Order matters here
                set(function beginGetFeatureFlags(draft) {
                    draft.unleash = unleash;
                    draft.context = context;
                    draft.status = FeatureFlagStoreStatus.LOADING;
                    if (defaultValues) {
                        draft.featureFlags = defaultValues;
                        logger.debugIfDev((_) =>
                            _(
                                `Feature Flags Defaults:\n${JSON.stringify(defaultValues, null, 2)}`,
                                'feature_flags_store',
                            ),
                        );
                    }
                });

                if (context) {
                    unleash.updateContext(context);
                }

                // Listen to update because it will also be emitted on initialization,
                // we don't need to handle ready or initialized.
                unleash.on('update', () => {
                    try {
                        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                        const featureFlags = unleash!
                            .getAllToggles()
                            .reduce((accumulator: Record<string, boolean>, current: IToggle) => {
                                accumulator[current.name] = current.enabled;
                                return accumulator;
                            }, {}) as FeatureFlagsRecord;

                        set(function endGetFeatureFlags(draft) {
                            draft.featureFlags = featureFlags;
                            draft.status = FeatureFlagStoreStatus.LOADED;
                            logger.debugIfDev((_) =>
                                _(
                                    `Feature Flags Response${
                                        get().context ? ` (${JSON.stringify(get().context)})` : ''
                                    }:\n${JSON.stringify(featureFlags, null, 2)}`,
                                    'feature_flags_store',
                                ),
                            );
                        });
                    } catch {
                        set(function getFeatureFlagsFailed(draft) {
                            draft.status = FeatureFlagStoreStatus.ERROR;
                            logger.warn(`Unable to retrieve updated feature toggles`, 'feature_flags_store');
                        });
                    }
                });

                unleash.on('error', () => {
                    set(function getFeatureFlagsFailed(draft) {
                        draft.status = FeatureFlagStoreStatus.ERROR;
                    });
                });

                // We have to setup all the promise and event handlers before calling start.
                // This should be the last thing we do in this function, other than waiting the primise.
                unleash.start();
                logger.debugIfDev((_) =>
                    _(`Feature Flags Request${context ? ` (${JSON.stringify(context)})` : ''}`, 'feature_flags_store'),
                );
            } catch (error) {
                handleNetworkWarning(error);
                logger.error('Encountered error starting feature flag service.', 'feature_flags_store');
                set(function getFeatureFlagsFailed(draft) {
                    draft.status = FeatureFlagStoreStatus.ERROR;
                });
            }

            return statusPromise;
        },
        reset() {
            set(function reset() {
                return initialState;
            });
        },
    }),
    'featureFlagsStore',
    IS_DEVELOPMENT,
);

export function selectStatus(state: FeatureFlagsState): FeatureFlagStoreStatus {
    return state.status;
}

export function selectFeatureFlags(state: FeatureFlagsState): FeatureFlagsRecord {
    return state.featureFlags;
}

export function selectFeatureFlagsLoaded(state: FeatureFlagsState): boolean {
    return state.status === FeatureFlagStoreStatus.LOADED || state.status === FeatureFlagStoreStatus.ERROR;
}
