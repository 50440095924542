/* eslint-disable @typescript-eslint/ban-ts-comment */
import { IS_DEVELOPMENT, IS_PRODUCTION, VERSION } from '~/modules/config';

// https://stackoverflow.com/a/57774317/706442
// @ts-ignore
export const isClient = typeof window !== 'undefined' && typeof window.document !== 'undefined';
// @ts-ignore
export const isServer = typeof module === 'object' && typeof module.exports === 'object';

export const CORRELATION_ID_PARAM = 'x-correlation-id';

type LogFn = (msg: string, ...args: unknown[]) => void;
type DevLogFn = (_: LogFn) => void;
type Logger = {
    log: LogFn;
    warn: LogFn;
    error: LogFn;
    debug: LogFn;
};

export let logger: Logger & { [key: string]: (...args: unknown[]) => void } & {
    // IfDev functions only log in development mode
    logIfDev: (_: DevLogFn) => void;
    warnIfDev: (_: DevLogFn) => void;
    errorIfDev: (_: DevLogFn) => void;
    debugIfDev: (_: DevLogFn) => void;
};

setCrossEnvLogger(console);

export function setCrossEnvLogger(crossEnvLogger: Logger) {
    const appType = isClient ? 'CLIENT' : isServer ? 'SERVER' : 'UNKNOWN';

    logger = Object.assign(Object.create(crossEnvLogger), {
        log: (msg: string, ...args: unknown[]) => crossEnvLogger.log(`${appType} v${VERSION} - ${msg}`, ...args),
        warn: (msg: string, ...args: unknown[]) => crossEnvLogger.warn(`${appType} v${VERSION} - ${msg}`, ...args),
        error: (msg: string, ...args: unknown[]) => crossEnvLogger.error(`${appType} v${VERSION} - ${msg}`, ...args),
        debug: (msg: string, ...args: unknown[]) => crossEnvLogger.debug(`${appType} v${VERSION} - ${msg}`, ...args),
        // IfDev functions only log in development mode
        logIfDev: (_: DevLogFn) =>
            runIfDev(_, (msg: string, ...args: unknown[]) =>
                crossEnvLogger.log(`${appType} v${VERSION} - ${msg}`, ...args),
            ),
        warnIfDev: (_: DevLogFn) =>
            runIfDev(_, (msg: string, ...args: unknown[]) =>
                crossEnvLogger.warn(`${appType} v${VERSION} - ${msg}`, ...args),
            ),
        errorIfDev: (_: DevLogFn) =>
            runIfDev(_, (msg: string, ...args: unknown[]) =>
                crossEnvLogger.error(`${appType} v${VERSION} - ${msg}`, ...args),
            ),
        debugIfDev: (_: DevLogFn) =>
            runIfDev(_, (msg: string, ...args: unknown[]) =>
                crossEnvLogger.debug(`${appType} v${VERSION} - ${msg}`, ...args),
            ),
    });
    return logger;
}

export function randomUUID() {
    if (isClient) {
        // @ts-ignore
        return typeof crypto !== 'undefined' ? crypto.randomUUID() : require('uuid').v4();
    }
    // @ts-ignore
    return require('crypto').randomUUID();
}

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
export function runIfDev(fn: Function, ...args: any): void {
    if (IS_DEVELOPMENT) {
        fn(...args);
    }
}

export function throwDevelopmentOrLog(error: Error) {
    if (!IS_PRODUCTION) {
        throw error;
    }
    logger.error(error.message, { type: 'EXCEPTION', name: error.name, stack: error.stack });
}
